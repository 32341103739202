@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import 'material-icons/iconfont/material-icons.css';
@import "~ngx-spinner/animations/ball-clip-rotate.css";

body {
  font-family: "Barlow", sans-serif;
}
/* You can add global styles to this file, and also import other style files */
input:focus-visible {
  outline: 0;
}

.grecaptcha-badge {
  right: auto !important;
  left: 0;
  width: 70px !important;
  bottom: 0 !important;
  -webkit-transition: width 0.5s ease-in-out !important;
  -moz-transition: width 0.5s ease-in-out !important;
  -o-transition: width 0.5s ease-in-out !important;
  transition: width 0.5s ease-in-out !important;

  &:hover {
      width: 256px !important;
  }
}
body {
  background-color: #f9f8fd !important;
  font-family: "Barlow", sans-serif!important;
  font-size: 100%;
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-text-size-adjust: none;
}

.backdropBackground {
  filter: blur(20px);
  background-color: #caa4a4;
  opacity: 0.7 !important;
}
.container {
  max-width: 100% !important;
}
.main-content .footer {
  left: auto!important;
  right: auto!important;
  width: calc(100% - 265px);
  position: fixed;
  bottom: 0;
  z-index: 99;
  margin-left: 15px!important;
  max-width:100%;
}
:host ::ng-deep.mat-card {
  padding: 0 0 0 16px;
  box-shadow: none!important;
}
/* Extra small devices (phones, 600px and down) */
@media screen and (min-width: 200px) and (max-width: 683px) {
  .set {
    flex-wrap: wrap;
  }
  .tabset {
    margin-top: 0 !important;
    text-align: left !important;
  }
  .tabset > label {
    padding: 10px;
    font-size: 12px;
  }
  .set {
    display: block !important;
  }
  .tabset > label {
    padding: 10px 17px !important;
  }
  .btns {
    margin: 10px 0;
  }
  .profile-sec {
    margin: 0 !important;
  }
}

@media screen and (min-width: 684px) and (max-width: 1024px) {
  .set {
    flex-wrap: wrap;
  }
  .tabset {
    margin-top: 0 !important;
    text-align: left !important;
  }
  .tabset > label {
    padding: 10px;
    font-size: 12px;
  }

  .tabset > label {
    padding: 10px 17px !important;
  }
  .btns {
    margin: 10px 0;
  }
  .profile-sec {
    margin: 0 !important;
  }
}
.blurred {
  backdrop-filter: blur(3px);
}
.blurr {
  backdrop-filter: blur(3px);
  background: rgba(0, 0, 0, 0.5);
}
.cardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 30.5em;
}
.form-vertical2 .otp-validation-form .ngx-otp-input-box input {
  width: 100%;
  height: 60px;
  text-align: center;
  font-size: 1.25rem;
  border: transparent;
  outline: 0;
  background: #f2f2f2;
  // border: 1px solid #EF3061;
  border-radius: 3px;
}

.form-vertical2 .otp-validation-form .ngx-otp-input-box input:focus {
  border: 1px solid #1059A9;
}
// Mobile Responsive ended //
@media screen and (min-width: 1024px) and (max-width: 1300px) {
  body {
    font-size: 80%!important;
  }
  .form-control {
    font-size: 1em!important;
  }
  :host ::ng-deep .mat-cell {
  font-size: 80%;
}
}
.mat-datepicker-content .mat-calendar {
  opacity: 1 !important;
}

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td.ngx-mat-timepicker-meridian .mat-button{
  margin-top: -5px;
    margin-left: 5px;
}

.mat-pseudo-checkbox {
  border-color: #667085 !important;
  border-radius: 4px !important;
}

.mat-pseudo-checkbox-checked {
  background: transparent !important;
}

.mat-pseudo-checkbox::after {
  color: #667085 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:#1059a9c9; 
}
.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:#1059a9c9;
  background-color:#1059a9c9 ;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:#1059a9c9;
}

.mat-radio-outer-circle {
  border-color: rgb(16 89 169);
}

.cesium-credit-logoContainer {
  display: none !important;
}

.mat-option.mat-active {
  background: rgba(16, 89, 169, 0.1) !important;
  color: #1059A9 !important;
}

.mat-option:hover:not(.mat-option-disabled) {
  background: rgba(16, 89, 169, 0.1) !important;
  color: #1059A9 !important;
}

.mat-checkbox-checkmark-path {
  stroke: #1059a9 !important;
}

.mat-checkbox-frame {
  border-color: #1059a9 !important;
  border-radius: 4px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent !important;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: transparent !important;
}

a.active {
  color: #1059a9 !important;
}

.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: rgb(137, 135, 135) !important;
}

app-cart .mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #1059a9 !important;
}

.timepicker__header {
  background-color: #1059A9 !important;
}

.timepicker-button {
  color: #1059A9 !important;
}

.mat-tooltip {
  max-width: 100%;
  font-size: 13px !important;
  font-family: 'Barlow', sans-serif !important;
}


/* Dark Theme Styles */
// Define a dark theme
.dark-theme {
  background-color: #121212 !important;
  color: #ffffff !important;

  tr,
  mat-row {
    background-color: #121212 !important;
  }

  td.mat-cell,
  mat-cell {
    color: white !important;
    border-bottom-color: rgb(71 70 70 / 30%) !important;
  }

  .sidebar {
    background-color: #050505 !important;
    border-right: 1px solid #050505 !important;
  }

  .sidebar-menu ul li span a {
    color: white !important;
  }

  header {
    background-color: #121212 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white !important;
  }

  .page-content {
    background-color: #121212 !important;
  }

  .footer {
    background-color: #121212 !important;
  }

  .mat-option {
    color: white !important;
  }

  .card {
    background-color: #121212 !important;
  }

  .scrollbar {
    background-color: #121212 !important;
  }

  .cart-items {
    background-color: #121212 !important;
  }

  .task-head {
    background-color: #121212 !important;
    border-bottom: 1px solid #454444 !important;
  }

  .mat-expansion-panel {
    color: white !important;
    background-color: #121212 !important;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description,
  .mat-select-value,
  .mat-radio-button,
  .panel-head,
  .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
    color: white !important;
  }

  mat-form-field,
  input {
    background-color: #121212 !important;
    color: white !important;
  }

  input::placeholder {
    background-color: #121212 !important;
    color: #98A2B3 !important;
  }

  .mat-select-panel,
  .mat-autocomplete-panel,
  .mat-menu-panel,
  .triggers {
    color: white !important;
    background-color: #212121 !important;
  }

  mat-datepicker-toggle {
    color: white !important;
  }

  .mat-calendar-body-cell-content {
    color: white !important;
  }

  .top-bar {
    border-bottom: 1px solid #454444 !important;
  }

  mat-header-row {
    border: none !important;
  }

  mat-card {
    background-color: #121212 !important;
    color: white !important;
  }

  .mat-row:nth-child(odd) {
    background-color: #121212 !important;
  }

  .mat-row:nth-child(even) {
    background-color: #212121 !important;
  }

  .mat-row:nth-child(odd).highlighted-dark {
    background-color: #042e5b !important;
  }

  .mat-row:nth-child(even).highlighted-dark {
    background-color: #042e5b !important;
  }

  .mat-table .mat-row tbody tr:nth-child(odd) {
    background-color: #121212 !important;
  }

  .mat-table .mat-row tbody tr:nth-child(even) {
    background-color: #212121 !important;
  }


  app-my-task .mat-row:nth-child(4n-1) {
    background-color: #212121 !important;
  }
  app-my-task button {
    border: 2px solid #454444 !important;
    color: white !important;
  }

  table, p {
    color: white !important;
  }

  .mat-icon-button {
    border: none !important;
  }

  .right {
    border-bottom: 1px solid #646566 !important;
  }

  app-cart .card.cart-items {
    border: 1px solid #646566 !important;
  }

  app-cart .card {
    border: 1px solid #646566 !important;
  }

  .table-responsive,
  .main-panel,
  .text.mat-footer-cell-Cur {
    background-color: #121212 !important;
  }

  .table,
  .sub-head,
  .tdhead,
  td,
  .head-color,
  .change-log-description,
  .hover-button,
  .dark-text,
  .custom-standards,
  .standard,
  .standards,
  .latency,
  .menuIcon,
  .custom-option-group .mat-optgroup-label {
    color: white !important;
  }

  .details-summary,
  .details-summary span,
  .sensor-name,
  .no-data,
  .flex>div label {
    color: white !important;
  }

  .mat-menu-item:hover:not([disabled]) {
    background-color: #2c2b2b !important;
    color: #3f9aff !important;
  }

  .mat-menu-item {
    color: white !important;
  }

  .desc,
  .details-content,
  .scrollbars {
    background-color: #121212 !important;
    color: white !important;
    font-weight: 500;
  }

  .mat-checkbox-layout .mat-checkbox-label,
  mat-label,
  .card .left p,
  .profile-content-area span,
  .mat-date-range-input-separator,
  .temperature,
  .mat-optgroup-cell,
  .mat-typography b,
  .mat-calendar-table-header th,
  .option,
  .left-align p, .thanks p, .wrapper span,
  .mat-checkbox span,
  .lbl-Sucess,
  .lbl-msg,
  .retask-button,
  .text-style,
  .change-log-description,
  .log-description {
    color: white !important;
  }

  .custom-button {
    background-color: #121212 !important;
    color: white !important;
    border: 1px solid #454444 !important;
  }
    .white-button {
      background-color: #121212 !important;
      color: white !important;
      border: 1px solid #454444 !important;
    }
  
    .budget-button {
      background-color: #1059a9 !important;
      color: white !important;
    }
  .mat-dialog-container, .container {
    background-color: #121212 !important;
    color: white !important;
  }

  .mat-dialog-content {
    color: white !important;
  }

  app-taskcost .mat-select-placeholder {
    color: white !important;
  }

  app-contacts .mat-select-placeholder {
    color: #b9b8b8 !important;
  }
  
  app-taskcost .triggers {
    background-color: #121212 !important;
  }

  .mat-option {
    border-bottom: 1px solid #03030333 !important;
  }

  .mat-option-disabled,
  .select-head label span,
  .light-text,
  .more-button {
    color: #b8b7c8 !important;
  }

  .mat-option-disabled {
    color: #b8b7c8 !important;
    background-color: #202020 !important;
  }

  .mat-datepicker-content .mat-calendar {
    background-color: #121212 !important;
    color: white !important;
  }

  .mat-datepicker-content .mat-calendar-next-button,
  .mat-calendar-previous-button {
    color: white !important;
  }

  .mat-calendar-body-in-range>.mat-calendar-body-comparison-identical,
  .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
    background: #001a6c !important;
  }

  .mat-calendar-body-in-comparison-range>.mat-calendar-body-selected {
    background: #1d3faa;
}

  .mat-calendar-arrow {
    fill: white !important;
  }

  app-orders .tabset .tab-panel {
    border: 1px solid #252323 !important;
  }

  app-orders,app-deposits table {
    border-collapse: separate !important;
  }

  .tabset>input:checked+label {
    background-color: #121212 !important;
    color: #3f9aff !important;
  }

  app-deposits .table-class {
    border: 1px solid #252323 !important;
}

.payment .right {
  background-color: #121212 !important;
  color: white !important;
  border: 1px solid #4d4a4a !important
}

.main-panel {
  border: 1px solid #4d4a4a !important
}

.collection-result ul {
  background-color: #121212 !important;
  color: white !important;
  border: 1px solid #4d4a4a !important
}

.collection-result {
  border-bottom: none !important
}

.desc, .sub-heads, .sub-head, .profile-content-area {
  border: 1px solid #4d4a4a !important
}

app-privacypolicy ul li {
  color: white !important;
}

app-privacypolicy p {
  color: #e6e3e3 !important;
}

app-privacypolicy strong {
  color: white !important;
}

app-privacypolicy .heading1 {
  color: white !important;
}

app-my-task input::placeholder {
  color: white !important;
}

app-my-task .icon-search-button {
  border: none !important;
}

.mat-table , mat-row {
  border: 1px solid #252323 !important;
}

.upload label, label, .preview-details span.preview-content, .menu-item {
  color: white !important;
}

app-addbalance input {
  border: 1px solid #252323 !important;
  border-radius: 5px;
}

app-footer a {
  color: white !important;
}

app-footer  a.active {
  color: #3f9aff !important;
}

.preview-details {
  color: #b0b0b0 !important;
}

.mat-option-disabled span.standard,
.mat-option-disabled span.standards,
.mat-option-disabled span.currency,
.mat-option-disabled span.latency,
.mat-option-disabled div.stripe {
  color: rgb(255 255 255 / 65%) !important;
}

.mat-stroked-button {
  border: 1px solid #4f4c4c !important;
}

h4 span, .costamt span, .wrapper span.eula, .currency, .icon-search, .icon-filter,
.profile-content-area span.amount-color {
  color: #3f9aff !important;
  stroke: #3f9aff !important;
}

div.active svg path, a.active svg path, span a.active mat-icon, .change-my-color{
  fill: #3f9aff !important;
  stroke: #3f9aff !important;
  color: #3f9aff !important;
}

.map-active, .settings-active, .my-icon, strong, .currency,
.dropdown-button,
.emails {
  color: #3f9aff !important;
}

.mat-option.mat-active {
  color: #3f9aff !important;
  background: rgb(61 141 230 / 10%) !important;
}

.mat-option:hover:not(.mat-option-disabled) {
  color: #3f9aff !important;
  background: rgb(61 141 230 / 10%) !important;
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:#3f9aff !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:#3f9aff !important;
  background-color:#3f9aff !important;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color:#3f9aff !important;
}

.mat-radio-outer-circle {
  border-color: #3f9aff !important;
}

.mat-checkbox-checkmark-path {
  stroke: #3f9aff !important;
}

.mat-checkbox-frame {
  border-color: #3f9aff !important;
}

.mat-checkbox-disabled .mat-checkbox-frame {
  border-color: rgb(137, 135, 135) !important;
}

app-cart .mat-checkbox-disabled .mat-checkbox-frame {
  border-color: #3f9aff !important;
}

.mat-input-element {
  caret-color: white !important;
}

.icons {
  color:#3f9aff !important;
}

.timepicker__body, .clock-face, .timepicker__actions {
  background-color: #050505 !important;
}

.clock-face__number > span {
  color: white !important;
}

.value-added-option,
.vadp-dropdown-option.sticky-option {
  background-color: #141313 !important;
  color:#3f9aff !important;
}

.mat-select-panel .mat-optgroup-label {
  border-bottom: 1px solid #141313;
}

.standards {
  font-weight: 400 !important;
}

.bold-fonts {
  font-weight: 700 !important;
}

.dark-bg {
  background-color: #121212 !important;
  color: white !important;
}

.vadp-dropdown-option.sticky-option:hover:not(.mat-option-disabled) {
  background-color: 141313 !important;
}

td.mat-cell.border-style,
mat-cell.border-style {
  border-bottom-color: #252323 !important;
}

th.mat-header-cell.border-class {
  border-bottom-color: #3e3e3e  !important;
}
}
